/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { PieChart, Pie, Cell, Sector, Tooltip } from "recharts";

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
  } = props;
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

// eslint-disable-next-line react/prop-types
const PieChartBar = ({ data, colors }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setActiveIndex({
      activeIndex: index,
    });
  };
  return (
    <PieChart width={250} height={300}>
      <Tooltip />
      <Pie
        activeIndex={activeIndex.activeIndex}
        activeShape={renderActiveShape}
        data={data}
        innerRadius={50}
        outerRadius={80}
        fill="#8884d8"
        dataKey="total"
        onClick={onPieEnter}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default PieChartBar;
