import React from "react";
const ResponsiveParentsChart = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingBottom: "20rem",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ResponsiveParentsChart;
