import { Route, Switch } from "react-router-dom";

import { Box } from "@chakra-ui/react";
import React from "react";
import Simpeg from "page/simpeg/Simpeg";

const Layout = () => {
  return (
    <Switch>
      <Box pos="relative" w={["100%"]} px="1rem">
        <Route path="/">
          <Simpeg />
        </Route>
      </Box>
    </Switch>
  );
};

export default Layout;
