import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, Container, GridItem, Text } from "@chakra-ui/react";

import Loading from "components/globals/Loading";
import React from "react";
import ResponsiveParentsChart from "components/chart/ResponsiveParentsChart";
import { paramSatuanKerjaId } from "type/type";
const Golongan = ({ satuanKerjaId }: paramSatuanKerjaId) => {
  const [golongan, setGolongan] = React.useState<object[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const fetchGolongan = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://eniaja.pallakastudio.com/api/dashboard/simpeg/golongan?satuan_kerja_id=${satuanKerjaId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            PROVIDER: "usersiap",
            Authorization: `${process.env.REACT_APP_TOKEN}`,
          },
        },
      );
      type result = {
        data: object[];
      };
      const result: result = await response.json();
      setGolongan(result.data);
      return result;
    } catch {
      return "error messagge";
    } finally {
      setLoading(false);
    }
  }, [satuanKerjaId]);
  React.useEffect(() => {
    fetchGolongan();
  }, [satuanKerjaId]);
  return (
    <GridItem pos="relative" colSpan={{ base: 12, md: 6 }}>
      {loading && <Loading />}
      <Container variant="wrap-card">
        <Text>Golongan</Text>
        <Box w="full">
          <ResponsiveParentsChart>
            <ResponsiveContainer>
              <BarChart
                data={golongan}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill="#116DD5">
                  <LabelList dataKey="value" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </ResponsiveParentsChart>
        </Box>
      </Container>
    </GridItem>
  );
};

export default Golongan;
