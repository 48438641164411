import { Flex, Text, Progress, Box } from "@chakra-ui/react";
import React from "react";
type ProggressBar = {
  colorScheme: string;
  value: number;
  label: string;
};

const ProggressBar = ({ colorScheme, value, label }: ProggressBar) => {
  return (
    <Box my={4}>
      <Flex justifyContent="space-between">
        <Text>{label}</Text>
        <Text>{value}</Text>
      </Flex>
      <Progress
        border="1rem"
        borderRadius="1rem"
        colorScheme={colorScheme}
        size="sm"
        value={value}
      />
    </Box>
  );
};

export default ProggressBar;
