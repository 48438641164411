import { Box, Flex, GridItem, Text } from "@chakra-ui/react";

import React from "react";

export type CardProps = {
  title: string;
  image: { color: string; image: string };
  year: string;
  total: number | null;
  unit?: string;
};
const Card = ({ title, image, year, total, unit }: CardProps) => {
  return (
    <GridItem colSpan={{ base: 12, md: 6, lg: 4 }}>
      <Flex
        minHeight="143px"
        bg="white"
        px="1rem"
        border="1px"
        borderColor="white"
        boxShadow="lg"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="0.25rem"
      >
        <Box>
          <Text fontSize="lg">{title}</Text>
          <Text as="sup">{year}</Text>
          <Text>
            {total}
            {unit}
          </Text>
        </Box>
        <Box
          w="63px"
          h="63px"
          bg={image.color}
          rounded="full"
          d="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={`assets/images/icon-card/${image.image}.png`}
            alt="ic_satuan_kerja"
          />
        </Box>
      </Flex>
    </GridItem>
  );
};

export default Card;
