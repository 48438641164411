/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios from "axios";

const getListSkpd = async () => {
  const {
    data: { data },
  } = await axios.get("https://eniaja.pallakastudio.com/api/master/skpd", {
    headers: {
      Accept: "application/json",
      PROVIDER: "usersiap",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];
  const listSkpd = data.map(
    (dat: { satuan_kerja_id: number; satuan_kerja_nama: string }) => ({
      value: dat.satuan_kerja_id,
      label: dat.satuan_kerja_nama,
    }),
  );
  return listSkpd;
};

export { getListSkpd };
