import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Container, Flex, Grid, GridItem, Text } from "@chakra-ui/react";

import Circle from "components/globals/Circle";
import Loading from "components/globals/Loading";
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable babel/camelcase */
import React from "react";
import ResponsiveParentsChart from "components/chart/ResponsiveParentsChart";
import { paramSatuanKerjaId } from "type/type";

const Eselon = ({ satuanKerjaId }: paramSatuanKerjaId) => {
  const [eselon, setEselon] = React.useState<object[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const fetchEselon = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://eniaja.pallakastudio.com/api/dashboard/simpeg/eselon?satuan_kerja_id=${satuanKerjaId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            PROVIDER: "usersiap",
            Authorization: `${process.env.REACT_APP_TOKEN}`,
          },
        },
      );
      type result = {
        data: object[];
      };
      const result: result = await response.json();
      setEselon(result.data);
      return result;
    } catch {
      return "error messagge";
    } finally {
      setLoading(false);
    }
  }, [satuanKerjaId]);
  React.useEffect(() => {
    fetchEselon();
  }, [satuanKerjaId]);
  return (
    <GridItem pos="relative" colSpan={{ base: 12, md: 7 }}>
      {loading && <Loading />}
      <Container variant="wrap-card">
        <Text>Eselon</Text>
        <Box w="full" overflowY="scroll">
          <ResponsiveParentsChart>
            <ResponsiveContainer>
              <BarChart data={eselon}>
                <Tooltip />
                <YAxis />
                <XAxis dataKey="eselon_nm" />
                <CartesianGrid strokeDasharray="3 3" />
                <Bar dataKey="pria" fill="#041845">
                  <LabelList dataKey="pria" position="top" />
                </Bar>
                <Bar dataKey="wanita" fill="#FF0000">
                  <LabelList dataKey="wanita" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </ResponsiveParentsChart>
        </Box>
        <Grid
          w={{ base: "full", md: "50%" }}
          mx="auto"
          templateColumns="repeat(2, 1fr)"
          gap={4}
          px={4}
          my={6}
        >
          <Flex alignItems="center">
            <Circle color="#041845" />
            <Text mx={2}>Laki-Laki</Text>
          </Flex>
          <Flex alignItems="center">
            <Circle color="#FF0000" />
            <Text mx={2}>Perempuan</Text>
          </Flex>
        </Grid>
      </Container>
    </GridItem>
  );
};

export default Eselon;
