/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable import/order */
import * as React from "react";

import Card from "components/globals/Card";
import Eselon from "./Eselon";
import Golongan from "./Golongan";
import { Grid } from "@chakra-ui/react";
import Header from "components/globals/Header";
import Jabatan from "./Jabatan";
import JabatanKosong from "./JabatanKosong";
import JenisKelamin from "./JenisKelamin";
import Select from "react-select";
import TingkatPendidikan from "./TingkatPendidikan";
import Umur from "./Umur";
import { getListSkpd } from "../../stores/globals";
import { useQuery } from "react-query";

const Simpeg = () => {
  const [skpd, setSkpd] = React.useState<number | null>(null);
  const [pegawai, setPegawai] = React.useState<number | null>(null);
  const [asnPnsPppk, setasnPnsPppk] = React.useState<number | null>(null);
  const [cpns, setCpns] = React.useState<number | null>(null);
  const [pns, setPns] = React.useState<number | null>(null);
  const [pppk, setPppk] = React.useState<number | null>(null);
  const [satuanKerjaId, setSatuanKerjaId] = React.useState<string | number>("");
  const { data, isSuccess } = useQuery("listSkpd", getListSkpd);
  const fetchTotal = React.useCallback(async () => {
    try {
      const response = await fetch(
        "https://eniaja.pallakastudio.com/api/dashboard/total",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            PROVIDER: "usersiap",
            Authorization: `${process.env.REACT_APP_TOKEN}`,
          },
        },
      );
      type result = {
        data: {
          total: {
            skpd: number;
            pegawai: number;
            asn_pns_pppk: number;
            cpns: number;
            pns: number;
            pppk: number;
          };
        };
      };
      const result: result = await response.json();
      setSkpd(result.data.total.skpd - 4);
      setPegawai(result.data.total.pegawai);
      setasnPnsPppk(result.data.total.asn_pns_pppk);
      setCpns(result.data.total.cpns);
      setPns(result.data.total.pns);
      setPppk(result.data.total.pppk);
      return result;
    } catch {
      return "error messagge";
    }
  }, []);
  React.useEffect(() => {
    fetchTotal();
  }, []);
  return (
    <>
      <Header title="Data Statistik SIMPEG Kota Makassar" />
      {isSuccess && (
        <Select
          options={data}
          onChange={(e: any) => setSatuanKerjaId(e.value)}
        />
      )}
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
        gap={4}
        my={4}
      >
        <Card
          year=""
          title="Satuan Kerja"
          image={{ color: "#041845", image: "ic_apartemen" }}
          total={skpd}
        />
        <Card
          title="Pegawai"
          image={{ color: "#FF0000", image: "ic_people" }}
          year=""
          total={pegawai}
        />
        <Card
          title="ASN PNS PPPK"
          image={{ color: "#116DD5", image: "ic_people" }}
          year=""
          total={asnPnsPppk}
        />
        <Card
          title="PNS"
          image={{ color: "#116DD5", image: "ic_people" }}
          year=""
          total={pns}
        />
        <Card
          title="PPPK"
          image={{ color: "#116DD5", image: "ic_people" }}
          year=""
          total={pppk}
        />
        <Card
          title="CPNS"
          image={{ color: "#116DD5", image: "ic_people" }}
          year=""
          total={cpns}
        />
        {/* <Umur umur={umur} /> */}
        <Umur satuanKerjaId={satuanKerjaId} />
        <Golongan satuanKerjaId={satuanKerjaId} />
        <Jabatan satuanKerjaId={satuanKerjaId} />
        <Eselon satuanKerjaId={satuanKerjaId} />
        <JenisKelamin satuanKerjaId={satuanKerjaId} />
        <JabatanKosong />
        <TingkatPendidikan satuanKerjaId={satuanKerjaId} />
        {/* <TingkatPendidikan /> */}
      </Grid>
    </>
  );
};
export default Simpeg;
