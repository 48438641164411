/* eslint-disable babel/camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, Container, GridItem, Text } from "@chakra-ui/react";

import Loading from "components/globals/Loading";
import React from "react";
import { paramSatuanKerjaId } from "type/type";
import ProggressBar from "../../components/globals/ProggressBar";
interface umur {
  sixteen_to_twenty_five: number;
  twenty_six_to_thirty_five: number;
  thirty_six_to_forty_five: number;
  forty_six_to_fifty_five: number;
  fifty_five_to_sixty_five: number;
  greater_than_sixty_five: number;
  no_age_data: number;
}
const Umur = ({ satuanKerjaId }: paramSatuanKerjaId) => {
  const [umur, setumur] = React.useState<umur>({
    sixteen_to_twenty_five: 0,
    twenty_six_to_thirty_five: 0,
    thirty_six_to_forty_five: 0,
    forty_six_to_fifty_five: 0,
    fifty_five_to_sixty_five: 0,
    greater_than_sixty_five: 0,
    no_age_data: 0,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const fetchUmur = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://eniaja.pallakastudio.com/api/dashboard/simpeg/age?satuan_kerja_id=${satuanKerjaId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            PROVIDER: "usersiap",
            Authorization: `${process.env.REACT_APP_TOKEN}`,
          },
        },
      );
      type result = {
        data: umur;
      };
      const result: result = await response.json();
      setumur(result.data);
      return result;
    } catch {
      return "error messagge";
    } finally {
      setLoading(false);
    }
  }, [satuanKerjaId]);
  React.useEffect(() => {
    fetchUmur();
  }, [satuanKerjaId]);
  return (
    <GridItem pos="relative" colSpan={{ base: 12, md: 6 }}>
      {loading && <Loading />}
      <Container variant="wrap-card">
        <Text>Umur</Text>
        <Box w="full">
          <ProggressBar
            colorScheme="gray"
            label="16-25 Tahun"
            value={umur.sixteen_to_twenty_five}
          />
          <ProggressBar
            colorScheme="red"
            label="26-35 Tahun"
            value={umur.twenty_six_to_thirty_five}
          />
          <ProggressBar
            colorScheme="cyan"
            label="36-45 Tahun"
            value={umur.thirty_six_to_forty_five}
          />
          <ProggressBar
            colorScheme="blue"
            label="46-55 Tahun"
            value={umur.forty_six_to_fifty_five}
          />
          <ProggressBar
            colorScheme="red"
            label="56-65 Tahun"
            value={umur.fifty_five_to_sixty_five}
          />
          <ProggressBar
            colorScheme="gray"
            label=">65 Tahun"
            value={umur.greater_than_sixty_five}
          />
          <ProggressBar
            colorScheme="gray"
            label="Tidak Ada Data Umur"
            value={umur.no_age_data}
          />
        </Box>
      </Container>
    </GridItem>
  );
};

export default Umur;
