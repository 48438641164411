import { Box, Container, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { Rekap, paramSatuanKerjaId } from "type/type";

import Circle from "components/globals/Circle";
import Loading from "components/globals/Loading";
import PieChartBar from "components/chart/PieChartBar";
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

const JenisKelamin = ({ satuanKerjaId }: paramSatuanKerjaId) => {
  const [jenisKelamin, setJenisKelamin] = React.useState<Rekap[]>([
    {
      name: "",
      total: 0,
    },
  ]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const fetchJenisKelamin = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://eniaja.pallakastudio.com/api/dashboard/simpeg/gender?satuan_kerja_id=${satuanKerjaId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            PROVIDER: "usersiap",
            Authorization: `${process.env.REACT_APP_TOKEN}`,
          },
        },
      );
      type result = {
        data: object;
      };
      const result: result = await response.json();
      const transformed = Object.entries(result.data)
        .map(([name, total]) => ({
          name,
          total,
        }))
        .slice(1);
      setJenisKelamin(transformed);
      return result;
    } catch {
      return "error messagge";
    } finally {
      setLoading(false);
    }
  }, [satuanKerjaId]);
  React.useEffect(() => {
    fetchJenisKelamin();
  }, [satuanKerjaId]);
  const colors = ["#041845", "#FF0000"];
  const names = ["Laki- Laki", "Perempuan"];
  return (
    <GridItem pos="relative" colSpan={{ base: 12, md: 5 }}>
      {loading && <Loading />}
      <Container variant="wrap-card">
        <Text>Jenis Kelamin</Text>
        <Box d="flex" justifyContent="center">
          {jenisKelamin && <PieChartBar data={jenisKelamin} colors={colors} />}
        </Box>
        <Grid w="full" templateColumns="repeat(2, 1fr)" gap={4} px={4}>
          {jenisKelamin.map((item, index) => (
            <Flex key={index} alignItems="center">
              <Circle color={colors[index]} />
              <Box d="flex" mx={2}>
                <Text mr={1}>{names[index]}</Text>( <Text>{item.total}</Text>)
              </Box>
            </Flex>
          ))}
        </Grid>
      </Container>
    </GridItem>
  );
};

export default JenisKelamin;
