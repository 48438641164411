import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Container, GridItem, Text } from "@chakra-ui/react";

import Loading from "components/globals/Loading";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React from "react";
// import ResponsiveParentsChart from "components/chart/ResponsiveParentsChart";
import { paramSatuanKerjaId } from "type/type";

const TingkatPendidikan = ({ satuanKerjaId }: paramSatuanKerjaId) => {
  const [tingkatPendidikan, setTingkatPendidikan] = React.useState<object[]>(
    [],
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const fetchTingkatPendidikan = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://eniaja.pallakastudio.com/api/dashboard/simpeg/pendidikan?satuan_kerja_id=${satuanKerjaId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            PROVIDER: "usersiap",
            Authorization: `${process.env.REACT_APP_TOKEN}`,
          },
        },
      );
      type result = {
        data: object[];
      };
      const result: result = await response.json();
      setTingkatPendidikan(result.data);
      return result;
    } catch {
      return "error messagge";
    } finally {
      setLoading(false);
    }
  }, [satuanKerjaId]);
  React.useEffect(() => {
    fetchTingkatPendidikan();
  }, [satuanKerjaId]);
  const colors = [
    "#041845",
    "#FF0000",
    "#116DD5",
    "#041845",
    "#116DD5",
    "#778192",
    "#FF0000",
    "#778192",
    "#041845",
    "#116DD5",
    "#778192",
    "#FF0000",
    "#778192",
  ];
  return (
    <GridItem pos="relative" colSpan={12}>
      {loading && <Loading />}
      <Container variant="wrap-card">
        <Text>Tingkat Pendidikan</Text>
        <Box w="full" overflowY="scroll">
          {tingkatPendidikan && (
            // <ResponsiveParentsChart>
            <ResponsiveContainer minWidth={1600} height={700}>
              <BarChart
                data={tingkatPendidikan}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <Tooltip />
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis type="category" dataKey="pendidikan_nama" />
                <XAxis type="number" />
                <Bar dataKey="jumlah" fill="#116DD5" barSize={20}>
                  {tingkatPendidikan.length !== null &&
                    tingkatPendidikan.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={colors[index % colors.length]}
                      />
                    ))}
                  <LabelList dataKey="jumlah" position="center" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            // </ResponsiveParentsChart>
          )}
        </Box>
      </Container>
    </GridItem>
  );
};

export default TingkatPendidikan;
