import React, { useState } from "react";
import { SideBarContext } from "./GlobalContext";

const SideBarProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [showSideBar, setshowSideBar] = useState("none");
  const handleSetshowSideBar = () => {
    if (showSideBar === "none") setshowSideBar("block");
    else setshowSideBar("none");
  };
  const [loading, setLoading] = useState<boolean>(true);
  const handleLoading = () => {
    if (loading) setLoading(true);
    else setLoading(true);
  };
  return (
    <SideBarContext.Provider
      value={{ showSideBar, handleSetshowSideBar, loading, handleLoading }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export default SideBarProvider;
