import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

const Loading = () => {
  return (
    <Box
      pos="absolute"
      d="flex"
      justifyContent="center"
      alignItems="center"
      bg="#5D5D5D5E"
      top="0"
      bottom="0"
      w="full"
      h="full"
      zIndex="10000"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Box>
  );
};

export default Loading;
