import { Box, Heading } from "@chakra-ui/react";
import React from "react";
type Props = {
  title: string;
};
const Header = ({ title }: Props) => {
  return (
    <Box my="24px">
      <Heading as="h2" size="sm">
        {title}
      </Heading>
    </Box>
  );
};

export default Header;
