import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Layout from "layout/Layout";
import "leaflet/dist/leaflet.css";
import "react-datepicker/dist/react-datepicker.css";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import SideBarProvider from "./context/SideBarProvider";
import theme from "./theme/theme";
import "react-circular-progressbar/dist/styles.css";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ChakraProvider theme={theme}>
          <SideBarProvider>
            <Layout />
          </SideBarProvider>
        </ChakraProvider>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
