import { extendTheme } from "@chakra-ui/react";

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: {
    brand: {
      50: "#44337A",
      100: "#B794F4",
      500: "#B794F4", // you need this
    },
    blue: {
      900: "#041845",
    },
    gray: {
      100: "#E5E5E5;",
    },
    // grayFg: "#778192",
    // redFg: "#FF0000",
    // blueFg: {
    //   100: "#116DD5",
    //   200: "#041845",
    // },
  },
  fonts: {
    body: "Roboto",
  },
  components: {
    Container: {
      variants: {
        "wrap-card": {
          minHeight: "30rem",
          bg: "white",
          p: "1rem",
          boxShadow: "lg",
          border: "1px",
          borderColor: "white",
          borderRadius: "0.25rem",
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          maxWidth: "100",
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    Checkbox: {
      baseStyle: {
        marginRight: "1rem",
        backgroundColor: "#fff",
      },
    },
  },
});
export default theme;
