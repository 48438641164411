import {
  Box,
  Container,
  Flex,
  GridItem,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import Loading from "components/globals/Loading";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable babel/camelcase */
import React from "react";
interface jabatanKosong {
  id: number;
  eselon_nm: string;
  jumlah: number;
}

const JabatanKosong = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [paramJabatanKosong, setparamJabatanKosong] = React.useState("eselon");
  const [jabatanKosong, setJabatanKosong] = React.useState<jabatanKosong[]>([]);
  const fetchJabatanKosong = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://eniaja.pallakastudio.com/api/dashboard/simpeg/jabatan-kosong/${paramJabatanKosong}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            PROVIDER: "usersiap",
            Authorization: `${process.env.REACT_APP_TOKEN}`,
          },
        },
      );
      type result = {
        data: { eselon_nm: string; jumlah: number }[];
      };
      const result: result = await response.json();
      const transformed = result.data.map((obj, index) => ({
        ...obj,
        id: index + 1,
      }));
      setJabatanKosong(transformed);
      return result;
    } catch {
      return "error messagge";
    } finally {
      setLoading(false);
    }
  }, [paramJabatanKosong]);
  const handleJabatanKosong = (e: { target: { value: string } }) => {
    setparamJabatanKosong(e.target.value);
  };
  React.useEffect(() => {
    fetchJabatanKosong();
  }, [paramJabatanKosong]);
  return (
    <GridItem pos="relative" colSpan={{ base: 12, md: 7 }}>
      {loading && <Loading />}
      <Container variant="wrap-card">
        <Flex
          justifyContent="space-between"
          flexWrap={{ base: "wrap", md: "nowrap" }}
        >
          <Box w={{ base: "100%", md: "50%" }}>
            <Text>Jabatan Kosong</Text>
          </Box>
          <Select onChange={handleJabatanKosong}>
            <option value="eselon">Eselon</option>
            <option value="noneselon">Non Eselon</option>
          </Select>
        </Flex>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>Keterangan</Th>
              <Th>Jumlah</Th>
            </Tr>
          </Thead>
          <Tbody>
            {jabatanKosong.length !== 0 &&
              jabatanKosong.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.id}</Td>
                  <Td>{item.eselon_nm}</Td>
                  <Td>{item.jumlah}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Container>
    </GridItem>
  );
};

export default JabatanKosong;
